import React from 'react'

// Our global context with default values
export const GlobalContext = React.createContext({
  priceFilter: 'all',
  setPriceFilter: () => {},
  ratingFilter: 'all',
  setRatingFilter: () => {},
  typeFilter: 'all',
  setTypeFilter: () => {},
  areaFilter: 'all',
  setAreaFilter: () => {},
  reset: false,
  setReset: () => {},
  mapView: true,
  setMapView: () => {},
  markerCount: 0,
  setmarkerCount: () => {}
})

// global provider component with state
const GlobalProvider = props => {

  const [priceFilter, setPriceFilter]  = React.useState('all');
  const [ratingFilter, setRatingFilter] = React.useState('all');
  const [typeFilter, setTypeFilter] = React.useState('all');
  const [areaFilter, setAreaFilter] = React.useState('all');
  const [reset, setReset] = React.useState(false);
  const [mapView, setMapView] = React.useState(true);
  const [markerCount, setmarkerCount] = React.useState(1);

  const value = { 
      priceFilter, 
      setPriceFilter, 
      ratingFilter, 
      setRatingFilter, 
      typeFilter, 
      setTypeFilter,
      areaFilter, 
      setAreaFilter,
      reset, 
      setReset,
      mapView,
      setMapView,
      markerCount,
      setmarkerCount,
    }

  return (
    <GlobalContext.Provider value={value}>
      {props.children}
    </GlobalContext.Provider>
  )
}

// Exports a GlobalProvider wrapper
export default ({ element }) => <GlobalProvider>{element}</GlobalProvider>