// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-articles-js": () => import("./../../../src/pages/articles.js" /* webpackChunkName: "component---src-pages-articles-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-premium-listing-js": () => import("./../../../src/pages/premium-listing.js" /* webpackChunkName: "component---src-pages-premium-listing-js" */),
  "component---src-pages-recommend-js": () => import("./../../../src/pages/recommend.js" /* webpackChunkName: "component---src-pages-recommend-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-recommendation-js": () => import("./../../../src/templates/recommendation.js" /* webpackChunkName: "component---src-templates-recommendation-js" */)
}

